import React, {useEffect, useRef, useState} from "react";
import * as styles from "./DonateForm.module.scss";
import axios from "axios";
import classNames from "classnames";
import {useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import CurrencyInput from "react-currency-input-field";
import logo from "../static/imgs/visa_logo.png";
import {PanelDefault} from "./PanelDefault";
import {Link} from "react-router-dom";
import urls from "../data/urls";

// document.bootstrapcss.replace(css);
// const css2 = new CSSStyleSheet();
// let text = JSON.stringify(css);
// console.log(css);
// css2.replace(text);
// document.adoptedStyleSheets = [css2,...document.adoptedStyleSheets, ]
// console.log(document.adoptedStyleSheets.length)

// console.log(document.getElementsByTagName('link'))

function SelectButtons({items, register}) {
    // console.log(items)
    const [state, setState] = useState(0);
    let items_html = "";
    try {
        items_html = items.length !== 0 ? items.map((item, index) => (
            <label className={classNames("btn btn-default", index === state && "active")} key={index}>
                <input {...register} type="radio" value={item.id} checked={index === state && "checked"}/>
                <label>{item.name}</label>
            </label>)) : <div>Не удалось загрузить проекты</div>;
    } catch (err) {
        items_html = items.length;
    }
    return <div className="btn-group-vertical" data-toggle="buttons">{items_html}</div>;
}

const subs = [
    {id: 1, name: 'Без подписки'},
    {id: 2, name: 'Три месяца'},
    {id: 3, name: 'Пол года'},
    {id: 4, name: 'Год'},
    {id: 5, name: 'Три года'}
];

const UnitellerSubmittingForm = ({ref, pay_url, shop_idp, order_idp, summa, sign, url_ok, url_return, email, lang}) => {
    return (<>
        <form id="uniteller_submitting_form" action={pay_url} method='post' ref={ref}>
            <input type='hidden' name='Shop_IDP' value={shop_idp}/>
            <input type='hidden' name='Order_IDP' value={order_idp}/>
            <input type='hidden' name='Subtotal_P' value={summa}/>
            <input type='hidden' name='Signature' value={sign}/>
            <input type='hidden' name='URL_RETURN_OK' value={url_ok}/>
            <input type='hidden' name='URL_RETURN_NO' value={url_return}/>
            {email && <input type='hidden' name='Email' value={email}/>}
            <input type="hidden" name="Language" value={lang}/>
        </form>
        {/*<script src="/static/donate_form_order_submit.js"></script>*/}
    </>)
};

const MoneyInput = ({name, control}) => {
    // console.log(control);
    // return <div></div>;
    const isMoreThenZero = (value) => {
        console.log(value);
        return value > 0;
    }

    return <Controller
        control={control}
        // id="input-example"
        name={name}
        // defaultValue={0}


        render={({field}) => {
            // console.log(field)
            return <CurrencyInput
                styleName="summa-control"
                className="form-control"
                //{...field}
                // styleName="text-input"
                rules={{validate: isMoreThenZero}}
                // disabled
                // id="input-example"
                // caption="Сумма"
                // ref={field.ref}
                // name={field.name}
                onValueChange={field.onChange}
                onBlur={field.onBlur}
                value={field.value}

                placeholder="Сумма пожертвования"
                prefix="₽"
                // suffix={"₽"}
                // // defaultValue={field.value}
                decimalsLimit={2}
            />
        }}
    />
};

const ProjectsPane = ({id, projects, isActive}) => {
    const auth = useSelector((state) => state.auth)
    const form = useForm();
    const [unitellerForm, setUnitellerForm] = useState();
    let unitellerFormRef = useRef();

    useEffect(() => {
        // unitellerForm && form.current?.submit();
        // console.log(document.getElementById("uniteller_submitting_form"));
        unitellerForm && document.getElementById("uniteller_submitting_form").submit();
        // unitellerForm && document.forms[0].submit()
    }, [unitellerForm])

    const onSubmit = (data) => {

        // console.log(data);
        axios.post(urls.flask.uniteller.pay, data)
            .then((result) => {
                console.log(result)
                if (result.status === 200) {
                    // window.open(result.data['confirmation_url'], '_self');
                    setUnitellerForm(UnitellerSubmittingForm({...result.data.form, ref: unitellerFormRef}));
                }
            })
            .catch(e => {
                console.log(e)
            })
    };
    const isAgree = form.watch("agree");
    const isHasSum = form.watch("summa");
    // console.log(form);
    return (<div className={classNames("tab-pane", isActive)} id={id}>
        {unitellerForm}
        <form onSubmit={form.handleSubmit(onSubmit)}>
            {/*{{form.hidden_tag()}}*/}
            <div styleName="donate-form-layout">
                <SelectButtons register={form.register("project_id")} items={projects}/>
                {auth.isAuth && <SelectButtons register={form.register("subs")} name="tabs" items={subs}/>}
                <div styleName={"column"}>
                    {/*<div className="input-group">*/}
                    {/*    <input className="form-control" placeholder={"Сумма пожертвования"}*/}
                    {/*           type="number" {...form.register("summa", {required: true})}/>*/}
                    {/*    <span className="input-group-addon">р.</span>*/}
                    {/*</div>*/}
                    <MoneyInput name={"summa"} control={form.control}/>
                    <div className={styles.checkboxOffert}>
                        <label>
                            <input id="agr" type="checkbox"{...form.register("agree", {required: true})}
                                   value="1"/>
                            <span>Подтверждаю согласие с </span>
                            {/*<a href="/offert">договором оферты</a>*/}
                            <Link to="/offer">договором оферты</Link>
                        </label>
                    </div>
                    <div>
                        {/*<a href="/garanty">Гарантии безопасности платежей</a>*/}
                        <Link to="/garanty">Гарантии безопасности платежей</Link> и <Link to="/return_policy">условия
                        возврата</Link>
                    </div>
                    <div>
                        <input className="btn btn-primary" type="submit"
                               value="Оплатить!" disabled={form.formState.isSubmitting || !isAgree || !isHasSum}/>
                    </div>
                    {/*<div><img src="/static/visa2.png"/></div>*/}
                    <img styleName="logo" src={logo} alt="visa_logo"/>
                </div>
            </div>
        </form>

    </div>);
};

const TabNavButton = ({id, caption, isActive}) => {
    return <li className={classNames("nav-item", isActive && 'active')}>
        <a className="nav-link" id={`${id}-tab`} data-toggle="tab" href={`#${id}`} role="tab"
           aria-controls={id}
           aria-selected="true">{caption}</a>
    </li>;
}

const DonateForm = () => {
    const projects = useSelector((state) => state.projects);
    // console.log(projects);
    let priority_projects = [];

    try {
        priority_projects = projects.filter((item) => ["31", "24", "28", "25"].includes(item.id));
    } catch (err) {
        priority_projects = projects;
    }

    return <PanelDefault>
        <div>
            <ul className="nav nav-tabs">
                <TabNavButton id="home" caption={"Приоритетные"} isActive={true}/>
                <TabNavButton id="profile" caption={"Все"}/>
            </ul>
            <div className="tab-content">
                <ProjectsPane id={"home"} isActive={"active"} projects={priority_projects}/>
                <ProjectsPane id={"profile"} isActive={"fade"} projects={projects}/>
            </div>
        </div>
    </PanelDefault>;
};
export default DonateForm;